import * as React from "react"
import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AnimatePresence } from "framer-motion"

import "./about/news/news.scss"

import Layout from "../components/Layout"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import Title from "../components/Title"
import NewsComp from "../components/NewsComp"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import HelpChoosing from "../components/offers/HelpChoosing"
import FormPopup from "../components/popups/FormPopup"
import {SEO} from "../components/seo";

const News = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          strapiNewsPage {
              offer {
                  title
                  subtitle
                  button
              }
              titleDesc {
                  pageTitle
                  pageDescription
              }
          }
          allStrapiNews(sort: { fields: createdAt, order: DESC }) {
              nodes {
                  createDate
                  imgSquare {
                      url
                  }
                  slug
              }
          }
      }
  `)

  const { strapiNewsPage, allStrapiNews } = data

  const allNews = allStrapiNews.nodes

  const [formPopup, setFormPopup] = useState(false)

  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(allNews.length > 10)

  const [list, setList] = useState([...allNews.slice(0, 8)])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 6)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list])

  return (
    <div>
      <SEO title={strapiNewsPage?.titleDesc?.pageTitle} description={strapiNewsPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="newsPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <Title
            classAdd="photoPage__secondHeader"
            value={[
              ["red", "Новости"],
              ["dark", " и акции "],
            ]}
          />

          {
            // NEWS
            list.length > 0 && (
              <NewsComp data={list} classAdd={"news--comp m60_40"} />
            )
          }

          {
            // LOAD MORE DATA
            hasMore && (
              <div className=" moreButton">
                <button className="moreButton" onClick={handleLoadMore}>
                  Показать еще 6 новостей
                </button>
              </div>
            )
          }

          {
            // OFFER
            strapiNewsPage?.offer && (
              <HelpChoosing classAdd="m140" data={strapiNewsPage.offer} />
            )
          }
        </DoubleBlock>
      </Layout>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export default News
