import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Img from "react-cool-img"
import { MENU_LINKS } from "../consts/MenuLinks"
import { STRAPI_API } from "../consts/api"

import DiscountImg from "../../static/media/head/img-2.webp"

// data : { id, srcImg }[]
export default function NewsComp(props) {
  const { data, classAdd } = props

  const newsData = data => {
    return data.map(item => {
      return {
        id: item.strapi_id,
        srcImg: STRAPI_API + item.imgSquare?.url,
        slug: item.slug,
      }
    })
  }

  const boxMap = newsData(data).map((item, index) => (
    <motion.div
      className="news__cont-box"
      key={index}
      whileHover={{ scale: 1.03 }}
    >
      <motion.div
        style={{ borderRadius: 15 }}
        transition={{ duration: 0.3 }}
        whileHover={{
          scale: 1.03,
          filter:
            "drop-shadow(0px 0px 30px rgba(65, 226, 207, 0.15)) drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.1))",
        }}
      >
        <Link to={MENU_LINKS.news + `${item.slug}/`}>
          <Img
            lazy={true}
            style={{ backgroundColor: "rgba(174, 174, 174, 0.3)" }}
            src={item.srcImg + "?format=webp&width=400"}
            alt="img"
          />
        </Link>
      </motion.div>
    </motion.div>
  ))

  return (
    <div className={classAdd ? `news ${classAdd}` : "news"}>
      <div className="news__cont">
        <motion.div className="news__cont-box" whileHover={{ scale: 1.03 }}>
          <motion.div
            style={{ borderRadius: 15 }}
            transition={{ duration: 0.3 }}
            whileHover={{
              scale: 1.03,
              filter:
                "drop-shadow(0px 0px 30px rgba(65, 226, 207, 0.15)) drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.1))",
            }}
          >
            <Link to={MENU_LINKS.combo}>
              <Img
                lazy={true}
                style={{ backgroundColor: "rgba(174, 174, 174, 0.3)" }}
                src={DiscountImg}
                alt="img"
              />
            </Link>
          </motion.div>
        </motion.div>

        {boxMap}
      </div>
    </div>
  )
}
